import Big from 'big.js';
import { z } from 'zod';

export const bigNumberSchema = z.preprocess(
  (val) => {
    if (typeof val === 'string' || typeof val === 'number') {
      try {
        return new Big(val);
      } catch {
        return undefined;
      }
    }

    if (val instanceof Big) return val;

    return undefined;
  },
  z.custom<Big>((val) => val instanceof Big, 'Cannot create Big number from input.'),
);

export type BigNumber = z.infer<typeof bigNumberSchema>;

import { type Primitive, z } from 'zod';

// Type guards

export function isValidLiteralUnion<T extends z.ZodLiteral<unknown>>(literals: T[]): literals is [T, T, ...T[]] {
  return literals.length >= 2;
}

// Helper functions

export function createEnum(values: readonly [string, ...string[]]) {
  return z.enum(values);
}

export function createLiteralUnion<T extends Primitive>(constArray: readonly T[]) {
  const literalsArray = constArray.map((literal) => z.literal(literal));

  if (!isValidLiteralUnion(literalsArray)) {
    throw new Error('Zod literal union must have at least two elements');
  }

  return z.union(literalsArray);
}

export function createColumnResponse<T extends string>(values: readonly [T, ...T[]]) {
  const enumKeys = createEnum(values);
  return z.array(z.object({ column: enumKeys }));
}

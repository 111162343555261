import { z } from 'zod';
import { timeDefinitionEnumLower } from './enums';
import { valueUnitMetricSchema } from './shared';

const period = z.object({
  start_time: z.string(),
  end_time: z.string(),
});

const tags = z.record(z.string(), z.array(z.string().nullable()));

// Consumer and Supply latest response
export const latestPeriodResponse = z.object({
  month: period,
});

export type LatestPeriod = z.infer<typeof latestPeriodResponse>;

// Carbon Request
export const consumerCarbonRequestSchema = z.object({
  start_datetime: z.string(),
  end_datetime: z.string(),
  consumer_ids: z.array(z.string()).optional().nullable(),
  time_definitions: z.array(timeDefinitionEnumLower).nullable(),
  tags: tags.optional().nullable(),
});

export type ConsumerCarbonRequest = z.infer<typeof consumerCarbonRequestSchema>;

// Carbon Response Schemas
export const carbonMetricSchema = z.object({
  location_based: z.number().nullable(),
  market_based: z.number().nullable(),
  impact: z.number().nullable(),
  unit: z.string().nullable(),
});

const carbonMetricsAtTimeDefinitionSchema = z.object({
  time_definition: timeDefinitionEnumLower,
  metrics: carbonMetricSchema,
});

export const consumerCarbonMetricsResponseSchema = z.array(carbonMetricsAtTimeDefinitionSchema);
export type ConsumerCarbonResponse = z.infer<typeof consumerCarbonMetricsResponseSchema>;

// Consumer overview request
export const consumerOverviewRequestSchema = z.object({
  start_datetime: z.string(),
  end_datetime: z.string(),
  consumer_ids: z.array(z.string()).optional().nullable(),
  time_definitions: z.array(timeDefinitionEnumLower).nullable(),
  tags: tags.optional().nullable(),
});

export type ConsumerOverviewRequest = z.infer<typeof consumerOverviewRequestSchema>;

// Consumer overview response
export const consumerOverviewSchema = z.object({
  consumption: valueUnitMetricSchema,
  current: z.array(
    z.object({
      excess_generation_allocated: valueUnitMetricSchema,
      matching_generation_allocated: valueUnitMetricSchema,
      matching_generation_allocated_percentage: z.number(),
      time_definition: timeDefinitionEnumLower,
      total_generation_allocated: valueUnitMetricSchema,
      unmatched_consumption: valueUnitMetricSchema,
    }),
  ),
});

export type ConsumerOverview = z.infer<typeof consumerOverviewSchema>;

// Consumer time definitions request
export const consumerTimeDefinitionsRequestSchema = z.object({
  start_datetime: z.string(),
  end_datetime: z.string(),
  consumer_ids: z.array(z.string()).optional().nullable(),
  tags: tags.optional().nullable(),
});

export type ConsumerTimeDefinitionsRequest = z.infer<typeof consumerTimeDefinitionsRequestSchema>;

// Consumer time definitions response
export const consumerTimeDefinitionsResponseSchema = z.object({
  time_definitions: z.array(timeDefinitionEnumLower),
});

export type ConsumerTimeDefinitionsResponse = z.infer<typeof consumerTimeDefinitionsResponseSchema>;
import { durationEnum } from '~/schemas/enums';

export const perimeterProductionDevicesSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  country: z.string(),
  technology: z.string(),
  durations: z.array(durationEnum),
});

export type PerimeterProductionDevice = z.infer<typeof perimeterProductionDevicesSchema>;

export const perimeterConsumersSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  country: z.string(),
  production_devices_with_allocated_production: z.array(z.string()),
  durations: z.array(durationEnum),
});

export type PerimeterConsumer = z.infer<typeof perimeterConsumersSchema>;

export const perimeterSchema = z.object({
  start_datetime: z.string(),
  end_datetime: z.string(),
  durations: z.array(durationEnum),
  consumers_with_consumption: z.array(perimeterConsumersSchema),
  production_devices_with_production: z.array(perimeterProductionDevicesSchema),
});

export type PerimeterType = z.infer<typeof perimeterSchema>;

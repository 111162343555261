import { z } from 'zod';
import { bigNumberSchema } from '~/schemas/big-number';
import { createLiteralUnion } from '~/utils/zod';

export const filterTypes = z.union([z.literal('between'), z.literal('one_of')]);
export const aggregateFunctions = z.union([z.literal('sum'), z.literal('avg'), z.literal('min'), z.literal('max')]);

// Filter schemas
const periodStartFilter = z.object({
  type: filterTypes,
  from: z.string().datetime(), // Inclusive
  to: z.string().datetime(), // Exclusive
});

const stringArrayFilter = z.object({
  type: filterTypes,
  items: z.array(z.string()),
});

// Supply table schemas
const supplyColumns = ['supply', 'supply_allocated', 'supply_unallocated'] as const;

const supplyTableRequest = z.object({
  views: z.array(
    z.object({
      label: z.string(),
      metrics: z.array(
        z.object({
          column: createLiteralUnion(supplyColumns),
          aggregation_function: aggregateFunctions,
        }),
      ),
    }),
  ),
});

const supplyTableResponse = z.object({
  views: z.array(
    z.object({
      label: z.string(),
      columns: z.array(z.object({ column: z.enum(supplyColumns) })),
      data: z.array(z.array(bigNumberSchema as z.ZodType<Big>)),
    }),
  ),
});

// POST /metrics/supply

// TODO: create a function to create the schema for the requests and responses
// These will then need to be passed into the query hooks to validate the responses

// Request body
export const supplyRequestSchema = z.object({
  filters: z.object({
    period_start: periodStartFilter,
    production_device_client_reference: stringArrayFilter.optional(),
  }),
  tables: z.object({
    supply: supplyTableRequest.optional(),
  }),
});

export type SupplyRequest = z.infer<typeof supplyRequestSchema>;

// Response body
export const supplyResponseSchema = z.object({
  tables: z.object({
    supply: supplyTableResponse,
  }),
});

export type SupplyResponse = z.infer<typeof supplyResponseSchema>;
